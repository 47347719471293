<script>
export default {
  created() {
    const {
      token,
      form_id,
      first_name,
      email,
      org,
      gbuid,
      gborgid,
      redirectTo,
    } = this.$route.query;
    localStorage.setItem("accessToken", token);
    this.$store.dispatch("user/fetchUserDetailsByToken", token).then((res) => {
      localStorage.setItem("user", JSON.stringify(res.data));
      let routingUrl = `/${
        redirectTo ? redirectTo : "library"
      }?&org=${org}&gbuid=${gbuid}&gborgid=${gborgid}`;

      if (form_id) {
        console.log('ll',form_id);
        routingUrl += `&form_id=${form_id}`;
      }

      if (first_name) {
        routingUrl += `&first_name=${first_name}`;
      }

      if (email) {
        routingUrl += `&email=${email}`;
      }

      if (this.$route.hash) {
        routingUrl = routingUrl + this.$route.hash;
      }
      window.location.href = routingUrl;
    });
  },
};
</script>
